import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';

// Types
import { usePrevious } from 'react-use';
import { Row as TableRow } from 'react-table';
import { FormOptions, UseFormProps } from '../../../types/Table';

// Models
import { IdentityUser } from '../../../models/IdentityUser';

// Hooks
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Actions
// Components
import { Translated } from '../../../components/UI/Core';
import { Spinner } from '../../../components/UI/Spinner/Spinner';
import { BusinessClient } from '../../../models/BusinessClient';
import { Tenant } from '../../../models/Tenant';
import { IdentityUserUnsafe } from '../../../models/IdentityUserUnsafe';
import { updateIdentityUserUnsafe } from '../../../store/IdentityUsers/IdentityUsers.redux';

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface IdentityUsersReduxProps {
  initialValues?: Store;
  businessClients: BusinessClient[] | null;
  tenants: Tenant[] | null;
}

// Hook
export const useIdentityUsersUnsafeRedux = <T extends object>({
  initialValues,
  businessClients,
  tenants,
}: IdentityUsersReduxProps) => {
  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ identityUsers }) => identityUsers?.updating ?? false);
  const error = useAppSelector(({ identityUsers }) => identityUsers?.error ?? false);

  // Form State
  const [formEditModel, setFormEditModel] = useState(initialValues);
  const [businessClientId, setBusinessClientId] = useState('');
  const prevInitialValues = usePrevious(initialValues);

  // Submit Handling
  const onSubmit = useCallback(
    (identityUser: IdentityUserUnsafe) => {
      dispatch(updateIdentityUserUnsafe(identityUser));
    },
    [dispatch]
  );

  const tenantOptions = useMemo(() => {
    return (
      tenants
        ?.filter((x) => x.BusinessClientId === businessClientId)
        .map((y) => ({
          value: y.Id,
          label: y.Name,
        })) ?? []
    );
  }, [businessClientId, tenants]);

  // Form
  const EditIdentityUserForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as IdentityUser)}
        initialValues={formEditModel}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item name="UserName" label={<Translated id="identityUsers.name" />}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                initialValue={businessClientId}
                name="BusinessClientId"
                label={<Translated id="app.businessClient" />}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={(value) => {
                    setBusinessClientId(value);
                    form.setFieldValue(['TenantId'], tenants?.find((x) => x.BusinessClientId === value)?.Id ?? '');
                  }}
                  options={businessClients?.map((x) => ({ value: x.Id, label: x.Name })) ?? []}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item name="TenantId" label={<Translated id="app.tenant" />}>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={tenantOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [formEditModel, updating, businessClientId, businessClients, tenantOptions, onSubmit, tenants]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'IdentityUsers',
        Form: EditIdentityUserForm,
        labels: {
          createButton: <Translated id="identityUsers.form.update" />,
          drawerForm: <Translated id="identityUsers.form.update" />,
          submitButton: <Translated id="form.editButton" />,
        },
      } as FormOptions<T>),
    [EditIdentityUserForm]
  );

  const showEditForm = useCallback(
    <TRowObject extends object>(selectedRow?: TableRow<TRowObject>) => {
      setFormEditModel(selectedRow);
      setBusinessClientId((selectedRow as any)?.BusinessClientId ?? '');
    },
    [setFormEditModel]
  );

  // Effects
  useEffect(() => {
    // Update after fetching is complete
    if (prevInitialValues !== initialValues) {
      setFormEditModel(initialValues);
    }
  }, [prevInitialValues, initialValues]);

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showEditForm,
    }),
    [formOptions, updating, error, showEditForm]
  );
};
