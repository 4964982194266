import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';

// Models
import { useDispatch } from 'react-redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { useAppSelector } from '../../App/useRedux';
import { updateUnsafeCustomTableFilters } from '../../../store/IdentityUsers/IdentityUsers.redux';
import { BusinessClient } from '../../../models/BusinessClient';
import { Tenant } from '../../../models/Tenant';

interface UseIdentityUserUnsafeTableFilterProps {
  businessClients: BusinessClient[] | null;
  tenants: Tenant[] | null;
}

// Hook
export const useIdentityUserUnsafeTableFilter = ({
  businessClients,
  tenants,
}: UseIdentityUserUnsafeTableFilterProps) => {
  // State
  const dispatch = useDispatch();
  const { unsafeTableCustomFilters: customTableFilters } = useAppSelector(({ identityUsers }) => identityUsers);

  const tenantOptions = useMemo(() => {
    if (customTableFilters.businessClientId) {
      return businessClients
        ?.filter((x) => x.Id === customTableFilters.businessClientId)
        .map((x) => ({
          label: x.Name,
          options:
            tenants?.filter((y) => y.BusinessClientId === x.Id).map((y) => ({ value: y.Id, label: y.Name })) ?? [],
        }));
    }
    return (
      businessClients?.map((x) => ({
        label: x.Name,
        options: tenants?.filter((y) => y.BusinessClientId === x.Id).map((y) => ({ value: y.Id, label: y.Name })) ?? [],
      })) ?? []
    );
  }, [customTableFilters.businessClientId, businessClients, tenants]);

  // Components
  const FilterSelect = useCallback(
    () => (
      <div>
        <Select
          allowClear
          showSearch
          value={customTableFilters.businessClientId}
          placeholder={<Translated id="app.businessClient" />}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={businessClients?.map((x) => ({ value: x.Id, label: x.Name })) ?? []}
          onChange={(value) =>
            dispatch(
              updateUnsafeCustomTableFilters({
                ...customTableFilters,
                businessClientId: value,
                tenantId: undefined,
              })
            )
          }
          style={{ width: 140, marginRight: 16 }}
        />
        <Select
          allowClear
          showSearch
          value={customTableFilters.tenantId}
          placeholder={<Translated id="app.tenant" />}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={tenantOptions ?? []}
          onChange={(value) => dispatch(updateUnsafeCustomTableFilters({ ...customTableFilters, tenantId: value }))}
          style={{ width: 140, marginRight: 16 }}
        />
      </div>
    ),
    [customTableFilters, businessClients, dispatch, tenantOptions]
  );

  // Hook response
  return useMemo(
    () => ({
      FilterSelect,
      customTableFilters,
    }),
    [FilterSelect, customTableFilters]
  );
};
