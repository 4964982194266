import React, { useCallback, useEffect, useMemo } from 'react';

// Hooks
import { useIdentityUsersUnsafeRedux } from './useIdentityUsersUnsafeRedux';
import { useFormDrawer } from '../../Drawer/useFormDrawer';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Components
import { Translated } from '../../../components/UI/Core';
import { LocalStorageKeys } from '../../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../../constants/Utils/UIFunctions';
import { BasicIcon } from '../../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../../data/Breadcrumbs/Breadcrumbs';
import { useIdentityUserUnsafeTableFilter } from './useIdentityUserUnsafeTableFilter';
import { getIdentityUserUnsafeTableColumns } from '../../../data/Table/IdentityUserUnsafeTableColumns';

// Hook
export const useIdentityUserUnsafeList = <T extends object>() => {
  // Data
  const {
    data: identityUsers,
    pageCount,
    totalCount,
    fetch,
    pending,
  } = useData(ApiEndpoints.identityUsers.listUnsafe, null);

  const {
    data: tenants,
    fetch: fetchTenants,
    pending: pendingTenants,
  } = useData(ApiEndpoints.tenants.identityUnsafe, null);

  const {
    data: businessClients,
    fetch: fetchBusinessClients,
    pending: pendingBusinessClients,
  } = useData(ApiEndpoints.businessClients.list, null);

  // Redux
  const {
    formOptions,
    updating,
    error,
    showEditForm: showIdentityUsersEditForm,
  } = useIdentityUsersUnsafeRedux<T>({ businessClients, tenants });

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Table Filter
  const { FilterSelect, customTableFilters } = useIdentityUserUnsafeTableFilter({ businessClients, tenants });

  // Fetch + Append Filter Data
  const fetchData = useCallback((args: any) => fetch({ ...args, ...customTableFilters }), [fetch, customTableFilters]);

  // Callbacks
  const showEditForm = useCallback(
    ({ values }: any) => {
      showIdentityUsersEditForm(values);
      getFormDrawerProps().setOpen(true);
    },
    [getFormDrawerProps, showIdentityUsersEditForm]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.identityUsers.list }), []);
  const getIdentityUsersDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.IdentityUsers.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-auth-screen" style={{ marginRight: 8 }} />
          <Translated id="appMenu.identityUsers" />
        </>
      ),
      data: identityUsers ?? [],
      columns: getTableColumns(
        getIdentityUserUnsafeTableColumns({
          tenants,
          businessClients,
        }),
        LocalStorageKeys.IdentityUsers.table.hiddenColumns
      ),
      fetchData,
    }),
    [businessClients, fetchData, identityUsers, tenants]
  );
  const getIdentityUsersTableProps = useCallback(
    () => ({
      pending: pending || pendingTenants || pendingBusinessClients,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, pendingTenants, pendingBusinessClients, updating, pageCount, totalCount]
  );
  const getIdentityUsersCrudProps = useCallback(
    () => ({
      endpoint: getFormDrawerProps().endpoint,
      saveHiddenTableColumns,
      hideDeleteButton: true,
      hideSelectableRows: true,
      FilterSelect,
      onRowClick: (id: string) => showEditForm({ values: identityUsers?.find((x) => x.Id === id) }),
    }),
    [FilterSelect, getFormDrawerProps, identityUsers, showEditForm]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchTenants({ pageIndex: 0, pageSize: 1000000 });
    fetchBusinessClients({ pageIndex: 0, pageSize: 1000000 });
  }, [fetchTenants, fetchBusinessClients]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getIdentityUsersDataProps,
      getIdentityUsersTableProps,
      getIdentityUsersCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getIdentityUsersDataProps,
      getIdentityUsersTableProps,
      getFormDrawerProps,
      getIdentityUsersCrudProps,
    ]
  );
};
